import { graphql } from "gatsby";
import React from "react";
import { useWindowDimensions } from "@gravitywelluk/react-hooks";

import {
  IFluid,
  ILocalFile
} from "src/types/local-files";

import ArticleAuthor from "../components/article-author";
import FieldTechnicalArticle from "../components/field-technical-article";
import Footer from "../components/footer";
import Header from "../components/header";
import HelmetWrapper from "../components/helmet-wrapper";
import {
  articleSchema,
  breadCrumbSchema
} from "../components/html-schema-snippets";
import Layout from "../components/layout";
import PageTop from "../components/page-top";
import PageTopArticleHeading from "../components/page-top-article-heading";
import RelatedLatestArticles from "../components/related-latest-articles";
import { IProfileData } from "../pages/about-us";
import { IBlogArticleData } from "../pages/latest";
import { DynamicImageSrcString } from "./utils/dynamicImage";
import getProfile from "./utils/getProfile";

interface ILatestPostProps {
  nodeBlogArticle: IBlogArticleData;
}

interface IArticle {
  node: {
    title: string;
    created: string;
    path: {
      alias: string;
    };
    relationships: {
      author: IProfileData;
      field_cover_image: ILocalFile<IFluid>;
    };
  };
}

/**
 * Latest post
 */
const LatestPost: React.FC<{ data: ILatestPostProps }> = ({ data }) => {
  const { width } = useWindowDimensions();
  const article = data.nodeBlogArticle;
  const profile = getProfile(article.relationships.author);

  const articleSchemaData = articleSchema({
    type: "Article",
    title: article.title,
    description: article.field_meta_description,
    path: article.path.alias,
    datePublished: article.created,
    dateModified: article.changed,
    author:
      article.relationships.author.relationships.profile__team_profile[ 0 ]
        .field_full_name,
    imageUrls: [ DynamicImageSrcString(article.relationships.field_cover_image) ]
  });

  const breadcrumbsSchemaData = breadCrumbSchema([
    {
      name: "Latest",
      path: "/latest"
    },
    {
      name: article.title,
      path: article.path.alias
    }
  ]);

  const schemaJson = [ breadcrumbsSchemaData, articleSchemaData ];
  const relatedArticles = article.relationships.field_latest_related_content;
  let parseRelatedArticles: IArticle[] | null = null;

  /**
   * Dynamic background image depending the devices width
   */
  const backgroundImg = React.useMemo(() => {
    if (width && width < 600) {
      return DynamicImageSrcString(article.relationships.field_mobile_hero_image);
    }

    return DynamicImageSrcString(article.relationships.field_hero_image);
  }, [
    article.relationships.field_hero_image,
    article.relationships.field_mobile_hero_image,
    width
  ]);

  // Parse the related articles to get the data structure into the Typed state
  if (relatedArticles) {
    parseRelatedArticles = relatedArticles.map(article => {
      return { node: article };
    });
  }

  return (
    <Layout>
      <>
        <HelmetWrapper
          title={article.title}
          description={article.field_meta_description}
          ogType="article"
          ogImage={article.relationships.field_meta_image}
          updatedAt={article.changed}
          canonicalPath={article.path.alias}
          twitterAuthorUsername={profile.field_twitter_username}
        >
          <script type="application/ld+json">
            {JSON.stringify(schemaJson)}
          </script>
        </HelmetWrapper>

        <header
          role="banner"
          id="hero"
        >
          <Header
            initialLogo
            initialTheme="dark"
          />

          <PageTop
            backgroundImage={backgroundImg}
            overlayColor="black"
            height="small"
          >
            <PageTopArticleHeading
              className="top-content"
              type="basic"
              title={article.title}
              authoredOn={article.created}
              authoredBy={profile.field_full_name}
            />
          </PageTop>
        </header>

        <main
          id="main-content"
          role="main"
        >
          <h1 className="off-screen">
            {article.title}
          </h1>

          <FieldTechnicalArticle
            type="basic"
            readTime={article.field_approx_reading_time}
            paragraphs={article.relationships.field_technical_article}
          />

          <ArticleAuthor
            type="latest"
            profile={profile}
          />

          <aside>
            {parseRelatedArticles && (
              <RelatedLatestArticles
                invertContrast
                articles={parseRelatedArticles}
              />
            )}
          </aside>
        </main>

        <footer role="contentinfo">
          <Footer />
        </footer>
      </>
    </Layout>
  );
};

export default LatestPost;

export const query = graphql`
  query($slug: String!) {
    nodeBlogArticle(path: { alias: { eq: $slug } }) {
      ...getLatestData
    }
  }
`;
