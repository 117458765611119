import * as React from "react";

import {
  IFluid,
  ILocalFile
} from "src/types/local-files";

import ArticleTeaser from "../article-teaser";
import { FullWidthColumnWrapper } from "../confines-wrappers";
import { IProfileData } from "../../pages/about-us";
import styled from "../../theme/styled";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontSpaceHelper,
  fontWeightHelper
} from "../../theme/theme-helper";
import Icon from "../icon/icon";
import Link from "../link";

interface IProps {
  invertContrast?: boolean;
  articles: IArticle[];
  authorName?: string;
}

interface IRelatedLatestArticlesContainer {
  invertContrast: boolean;
}

interface IArticle {
  node: {
    title: string;
    created: string;
    path: {
      alias: string;
    };
    relationships: {
      author: IProfileData;
      field_cover_image: ILocalFile<IFluid>;
    };
  };
}

const RelatedLatestArticlesContainer = styled<
  IRelatedLatestArticlesContainer,
  "div"
>("div")`
  background-color: ${props =>
    props.invertContrast ?
      ({ theme }) => colourHelper({
        theme,
        variant: "altLightGrey"
      }) :
      ({ theme }) => colourHelper({
        theme,
        variant: "white"
      })};

  h2 {
    transition: 0.2s ease-in-out;
    margin: 0;
    color: ${({ theme }) => colourHelper({
    theme,
    variant: "black"
  })};
    font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "title"
  })};
    font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "heavy"
    })};
    line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
    text-align: center;

    .hr {
      display: block;
      padding-top: 2.4rem;

      span {
        display: block;
        margin: 0 auto;
        width: 5rem;
        height: 0.5rem;
        background-color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "brandColor"
    })};
      }
    }
  }

  .articles-container {
    transition: 0.2s ease-in-out;
    padding-top: 6.8rem;

    .articles-wrapper {
      transition: 0.2s ease-in-out;
      padding-top: 5.8em;

      &:first-child {
        padding-top: 0;
      }

      .more-link-container {
        position: relative;
        padding-top: 66.66666667%;

        .more-link-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          .more-link {
            margin: 0;
            color: ${({ theme }) => colourHelper({
    theme,
    variant: "black"
  })};
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_2"
    })};
            font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "heavy"
    })};
            line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
            text-align: center;

            a {
              display: block;
              position: relative;
              padding-top: 3.2rem;
              color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "black"
    })};

              &:hover {
                color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "brandColor"
    })};

                .icon-wrapper {
                  .icon {
                    fill: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "brandColor"
    })};
                  }
                }
              }

              .keyword {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "small_3"
    })};
                font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "light"
    })};
                line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "normalized"
    })};
                letter-spacing: ${({ theme }) =>
    fontSpaceHelper({
      theme,
      variant: "medium"
    })};
                text-transform: uppercase;
              }

              .icon-wrapper {
                display: block;
                padding-top: 3.2rem;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 600px) {
    h2 {
      font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_1"
    })};
    }

    .articles-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      .articles-wrapper {
        padding-top: 6.8rem;
        width: 50%;

        &:nth-child(-n + 2) {
          padding-top: 0;
        }

        &:nth-child(odd) {
          padding-right: 2%;

          &:last-child {
            padding-left: 1%;
            padding-right: 1%;
          }
        }

        &:nth-child(even) {
          padding-left: 2%;
        }
      }
    }
  }

  @media (min-width: 960px) {
    h2 {
      font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_2"
    })};
    }

    .articles-container {
      .articles-wrapper {
        padding-top: 8.2rem;
        width: 33.33%;

        &:nth-child(-n + 3) {
          padding-top: 0;
        }

        &:nth-child(3n + 1) {
          padding-left: 0;
          padding-right: 2%;

          &:last-child {
            padding-left: 1%;
            padding-right: 1%;
          }
        }

        &:nth-child(3n + 2) {
          padding-left: 1%;
          padding-right: 1%;

          &:last-child {
            padding-left: 2%;
            padding-right: 0;
          }
        }

        &:nth-child(3n + 3) {
          padding-left: 2%;
          padding-right: 0;

          &:last-child {
            padding-left: 2%;
            padding-right: 0;
          }
        }
      }
    }
  }

  @media (min-width: 1280px) {
    h2 {
      font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_3"
    })};
    }
  }

  @media (min-width: 1440px) {
    .articles-container {
      .articles-wrapper {
        .more-link-container {
          .more-link-wrapper {
            .more-link {
              font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "title"
    })};

              .keyword {
                font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "small_3"
    })};
              }
            }
          }
        }
      }
    }
  }
`;

const RelatedLatestArticles = ({
  invertContrast,
  authorName,
  articles
}: IProps) => {
  const invert = invertContrast ? invertContrast : false;
  const authorFirstName = authorName ? authorName.split(" ")[ 0 ] : "";
  const nameSlugRegex = /[\s'-]/i;
  let nameSlug = "";

  if (authorName) {
    nameSlug = authorName.replace(nameSlugRegex, "-").toLowerCase();
  }

  if (articles.length === 0) {
    return null;
  }

  return (
    <RelatedLatestArticlesContainer
      className="content-container"
      invertContrast={invert}
    >
      <FullWidthColumnWrapper>
        <h2>
          {authorName ?
            `${authorFirstName}'s Latest Articles` :
            "Related Latest Articles"}

          <span className="hr">
            <span />
          </span>
        </h2>

        <div className="articles-container">
          {articles.map((article, i) => {
            return (
              <div
                className="articles-wrapper"
                key={i}
              >
                <ArticleTeaser
                  htmlHeadingTag="h3"
                  created={article.node.created}
                  path={article.node.path.alias}
                  title={article.node.title}
                  author={article.node.relationships.author.relationships
                    .profile__team_profile[ 0 ].field_full_name}
                  cover={article.node.relationships.field_cover_image}
                />
              </div>
            );
          })}

          {authorName && (
            <aside className="articles-wrapper">
              <div className="more-link-container">
                <div className="more-link-wrapper">
                  <p className="more-link">
                    <span className="off-screen">
                      Click here to view
                      {" "}
                    </span>

                    <Link
                      type="none"
                      href={`/latest/${nameSlug}`}
                    >
                      <>
                        More
                        {" "}

                        <span className="keyword">
                          Latest
                        </span>

                        {" "}
                        articles by

                        {" "}

                        {authorFirstName}

                        <span className="icon-wrapper">
                          <Icon
                            className="icon"
                            icon="arrowRight"
                            size={2.4}
                          />
                        </span>
                      </>
                    </Link>
                  </p>
                </div>
              </div>
            </aside>
          )}
        </div>
      </FullWidthColumnWrapper>
    </RelatedLatestArticlesContainer>
  );
};

export default RelatedLatestArticles;
